export const tickUpdate = (cb): ((e?: any) => void) => {
  let ticking = false;

  const update = (e?: any) => {
    cb(e);
    ticking = false;
  };

  const requestTick = (e?: any) => {
    if (!ticking) {
      requestAnimationFrame(() => update(e));
      ticking = true;
    }
  };

  return requestTick;
};

export const random = (to: number, from = 0): number => {
  return Math.random() * (to - from) + from;
};

export const clamp = (num: number, min: number, max: number): number => {
  return Math.min(Math.max(num, min), max);
};

export const mapRange = (
  value: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

export const formToObject = (form: HTMLFormElement): Record<string, any> => {
  const fd = new FormData(form);
  return [...fd.entries()].reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: curr[1],
    }),
    {},
  );
};

export const clickOutside = (
  el,
  onClickOutside,
  validator?: (el: HTMLElement, evt: MouseEvent) => boolean,
) => {
  const cb = (e) => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside();
      }
    } else if (e.target !== el && !el.contains(e.target)) {
      onClickOutside();
    }
  };

  document.addEventListener("click", cb);
  const unregisterExits = registerExits(onClickOutside);

  return () => {
    unregisterExits();
    document.removeEventListener("click", cb);
  };
};

const KEYS = {
  ESCAPE: 27,
};

export const registerExits = (onEscape) => {
  const cb = (e) => {
    if ([KEYS.ESCAPE].includes(e.keyCode)) {
      onEscape();
    }
  };

  document.addEventListener("keyup", cb);
  return () => document.removeEventListener("keyup", cb);
};

export const shuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
