export const sendEvent = (name: string, extra = {}) => {
  if (!(window as any).gtag) {
    return;
  }

  (window as any).gtag("event", name, extra);
};

// window.MyNamespace = window.MyNamespace || {};

export const GTAG_ID = "G-JE9M1C4FWK";
export const COOKIE_NAME = "myp-analytics";

export const loadScripts = () => {
  return new Promise<void>((resolve) => {
    const script = document.createElement("script");
    script.onload = () => {
      console.log("GA Loaded");
      resolve();
    };
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;

    document.head.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${GTAG_ID}', { 'anonymize_ip': true });`;

    document.head.appendChild(script2);
  });
};

export const sendPageView = (url) => {
  if (!(window as any).gtag) {
    return;
  }

  (window as any).gtag("config", GTAG_ID, {
    page_location: url,
  });
};
