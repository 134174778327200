import { Cookies } from "react-cookie-consent";
import { registerBootlegVH } from "./src/utils/events";
import { COOKIE_NAME, loadScripts } from "./src/utils/analytics";

export const onClientEntry = () => {
  registerBootlegVH();

  const consent = Cookies.get(COOKIE_NAME);

  if (consent && parseInt(consent)) {
    loadScripts();
  }
};
